export class SiteModal {
    id;
    runID;
    latitude;
    longitude;
    infected;
    constructor({ id, runID, latitude, longitude, affected }) {
        this.id = id;
        this.runID = runID;
        this.latitude = latitude;
        this.longitude = longitude;
        this.infected = affected;
    }
}