import { RunOverviewModel } from "./RunOverviewModel";
import { SimpleRunModel } from "./SimpleRunModel";
import { SiteModal } from "./SiteModal";

export class RunModel {
    overview; // RunOverviewModel
    sites; // Array of SitesModel

    constructor({ overview, sites } = {}) {
        if (overview) {
            this.overview = new RunOverviewModel(overview);
        }

        let lSites = [];
        if (sites) {
            sites.forEach(site => {
                lSites.push(new SiteModal(site));
            });
        }
        this.sites = lSites;
    }

    getSimpleRunModel() {
        let infectionPercentage = this.sites.length > 0 ? (this.sites.filter(site => site.infected).length / this.sites.length) * 100 : 0;
        return new SimpleRunModel({ id: this.overview.id, name: this.overview.name, date: this.overview.dateOfRun, infestationPercentage: infectionPercentage })
    }
}