export class CustomMapMarker {
    SiteID;
    Latitude;
    Longitude;
    Color;
    StrokeColor;
    StrokeWidth;
    Opacity;
    Scale;
    Title;
    IsFixedScale = false;
    Data;
    Imagery;

    constructor({ siteID, latitude, longitude, color = "#FF0000", strokeColor = "#FFFFFF", strokeWidth = 1, opacity = 1, scale = 1, title = "", isFixedScale = false, data } = {})
    {
        this.SiteID = siteID;
        this.Latitude = latitude;
        this.Longitude = longitude;
        this.Color = color;
        this.StrokeColor = strokeColor;
        this.StrokeWidth = strokeWidth;
        this.Opacity = opacity;
        this.Scale = scale;
        this.Title = title;
        this.IsFixedScale = isFixedScale;
        this.Data = data;
    }

    getGoogleMapsMarker = (map, faMarkerIcon) => {
        return new window.google.maps.Marker({
            map: map,
            position: this.getGoogleLatLng(),
            icon: {
                path: faMarkerIcon.icon[4],
                fillColor: this.Color,
                fillOpacity: this.Opacity,
                anchor: new window.google.maps.Point(
                    faMarkerIcon.icon[0] / 2, // center of width
                    faMarkerIcon.icon[1] /2 // center of height
                ),
                strokeWeight: this.StrokeWidth,
                strokeColor: this.StrokeColor,
                scale: 0.05
            },
            title: this.Title,
            customMarkerData: this.Data
        });
    }

    getGoogleLatLng = () => {
        let latLng = new window.google.maps.LatLng(this.Latitude, this.Longitude);
        return latLng;
    }

    getSiteID = () => {
        return this.SiteID;
    }

    hasImagery = () => {
        return this.Imagery !== undefined;
    }

    isValidGeoLocation = () => {
        return this.isValidLatitude() && this.isValidLongitude();
    }

    isValidLatitude = () => {
        return !isNaN(this.Latitude) && isFinite(this.Latitude) && Math.abs(this.Latitude) <= 90;
    }

    isValidLongitude = () => {
        return !isNaN(this.Longitude) && isFinite(this.Longitude) && Math.abs(this.Longitude) <= 180;
    }
}