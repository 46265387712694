import React, { useEffect, useState } from "react";
import { Container, Table, Button, Spinner } from "react-bootstrap";
import FAWAlert from "../FAWAlert";
import { format } from "date-fns";
import Popover from "../Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowDown, faLongArrowUp } from "@fortawesome/free-solid-svg-icons";
import { getRuns, getRun } from "../../api/apiAccess";
import { RunOverviewModel } from "../../classes/RunOverviewModel";
import { RunModel } from "../../classes/RunModel";

const FindRunPopover = ({ onClose }) => {
    const [runs, setRuns] = useState([]);
    const [hasNoRuns, setHasNoRuns] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [sorting, setSorting] = useState({
        name: {
            asc: false,
            desc: true
        },
        batch: {
            asc: false,
            desc: false
        },
        date: {
            asc: false,
            desc: false
        },
        town: {
            asc: false,
            desc: false
        },
        crop: {
            asc: false,
            desc: false
        },
        resolution: {
            asc: false,
            desc: false
        }
    })

    useEffect(() => {
        fetchRuns();
    }, []);

    useEffect(() => {
        let targetSortingColumn = Object.keys(sorting).find(k => sorting[k].asc === true || sorting[k].desc === true);

        let tempRuns = [...runs];
        tempRuns = tempRuns.sort((a, b) => {
            // The property name in the sorting object doesn't match the cropTypeID property, so map it.
            let runTargetProperty = targetSortingColumn === "crop" ? "cropTypeID" : targetSortingColumn;
            //Sort for ascending
            if (sorting[targetSortingColumn].asc === true) {
                // Handle sorting dates and numbers
                if (a[runTargetProperty] instanceof Date || !isNaN(a[runTargetProperty])) {
                    return a[runTargetProperty] - b[runTargetProperty];
                }
                // Handle sorting strings
                return a[runTargetProperty] < b[runTargetProperty] ? 1 : -1;
            }
            else {
                // Sort for descending
                // Handle sorting dates and numbers
                if (a[runTargetProperty] instanceof Date || !isNaN(a[runTargetProperty])) {
                    return b[runTargetProperty] - a[runTargetProperty];
                }

                // handle sorting strings
                return a[runTargetProperty] > b[runTargetProperty] ? 1 : -1;
            }
        })
        setRuns(tempRuns);
    }, [sorting])

    const fetchRuns = async () => {
        setRuns([]);
        setError(false);
        setIsLoading(true);

        getRuns().then(res => {
            if (res?.status === 200) {
                let lRuns = [];
                if (res.data) {
                    res.data.forEach(run => {
                        lRuns.push(new RunOverviewModel(run));
                    })
                }

                setRuns(lRuns);
                setHasNoRuns(lRuns.length === 0);
                setIsLoading(false);
            }
            else {
                setError(true);
                setIsLoading(false);
            }
        }).catch(err => {
            setError(true);
            setIsLoading(false);
        });
    }

    const doClose = () => {
        onClose(undefined); // Pass undefined to return to main popover
    }

    const loadRun = (aID) => {
        const fetchRun = async () => {
            getRun(aID).then(res => {
                if (res?.status === 200) {
                    let lSite = new RunModel(res.data)
                    onClose(lSite);
                }
                else {
                    setError(true);
                }
            }).catch(err => {
                setError(true);
            });
        }

        fetchRun();
    }

    const tryAgain = () => {
        fetchRuns();
    }

    const sortBy = (sortingField) => {
        // Update sorting object
        let tempSorting = { ...sorting };
        Object.keys(tempSorting).forEach((field) => {
            if (field === sortingField) {
                tempSorting[field] = tempSorting[field].asc === true ? { asc: false, desc: true } : { asc: true, desc: false };
            } else {
                tempSorting[field] = { asc: false, desc: false };
            }
        })
        setSorting(tempSorting)
    }

    const SortableHeader = ({ headerText, sortingProperty }) => {
        return (
            <td className="field-label find-run-table-clickable-header" onClick={() => sortBy(sortingProperty)}>
                {headerText} <div style={{ display: "inline-block", width: "20px", fontSize: "12px", color: "#777777" }}>{Object.values(sorting[sortingProperty]).find(v => v === true) ? sorting[sortingProperty].desc ? <FontAwesomeIcon icon={faLongArrowDown} /> : <FontAwesomeIcon icon={faLongArrowUp} /> : null}</div>
            </td>
        )
    }

    return (
        <Popover screenFillPercentage={75} showClose={true} onClose={doClose} header="Previous Runs">
            <div className="full-height-container">
                <div className="pe-3 mx-0 d-inline-block h-100 w-100" style={{ overflowY: 'auto' }}>
                    <Container fluid>
                        {
                            isLoading ? (

                                <div className="mt-5 pt-5">
                                    <div className="d-flex justify-content-center w-100">
                                        <Spinner animation="grow" className="loading-runs-spinner" />
                                    </div>
                                    <div className="d-flex justify-content-center w-100 mt-3">Loading runs...</div>
                                </div>

                            ) : null
                        }
                        {
                            !isLoading && runs?.length > 0 ? (
                                <Table className="find-run-table">
                                    <thead>
                                        <tr>
                                            <SortableHeader headerText="Name" sortingProperty="name" />
                                            <SortableHeader headerText="Batch" sortingProperty="batch" />
                                            <SortableHeader headerText="Date of run" sortingProperty="date" />
                                            <SortableHeader headerText="Nearest town" sortingProperty="town" />
                                            <SortableHeader headerText="Crop type" sortingProperty="crop" />
                                            <SortableHeader headerText="Resolution" sortingProperty="resolution" />

                                            <td className="field-label">
                                                Notes
                                            </td>
                                            <td className="field-label">
                                                Action
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !isLoading && runs?.map((run, runIdx) => {
                                                return (
                                                    <tr key={`run_${runIdx}`}>
                                                        <td>
                                                            {run.name}
                                                        </td>
                                                        <td>
                                                            {run.batch}
                                                        </td>
                                                        <td>
                                                            {format(run.dateOfRun, "dd/MM/yyyy")}
                                                        </td>
                                                        <td>
                                                            {run.town}
                                                        </td>
                                                        <td>
                                                            {run.cropTypeID}
                                                        </td>
                                                        <td>
                                                            {run.altitude}
                                                        </td>
                                                        <td>
                                                            {run.notes}
                                                        </td>
                                                        <td>
                                                            <Button onClick={() => loadRun(run.id)}>Open</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            ) : null

                        }
                        {
                            !isLoading && hasNoRuns ?
                                (
                                    <FAWAlert bodyHTML={<div>There are no previous runs.</div>} />
                                ) : null
                        }

                        {
                            error ?
                                (
                                    <FAWAlert
                                        alertVariant="error"
                                        headingText="An unexpected error occurred"
                                        bodyHTML={<div>An unexpected error occurred while attempting to retrieve previous runs.</div>}
                                        actionButtonText="Try again"
                                        actionButtonVariant="error"
                                        onActionButtonClick={() => tryAgain()}
                                        closeButtonText="Cancel"
                                        closeButtonVariant="error"
                                        dismissable={true}
                                    />
                                ) : null
                        }
                    </Container>
                </div>
            </div>
        </Popover>
    )
}

export default FindRunPopover;