import React from "react";
import SimpleRunsInfo from "./SimpleRunsInfo";
import { Container, Row, Col } from "react-bootstrap";

const AreaRunsOverviewPanel = ({currentRun, otherRelevantRuns, onOtherRunClicked}) => {
    return (
        <Container className="area-runs-container p-3">
            <Row>
                <Col xs={12}>
                    Currently viewing
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <SimpleRunsInfo runs={[currentRun]} showOpenButton={false} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    Other runs in this area
                </Col>
            </Row>
            
            {
                otherRelevantRuns?.length > 0 ? (
                    
                    <Row className="px-3">   
                        <SimpleRunsInfo runs={[...otherRelevantRuns]} showOpenButton={true} onOpenRun={onOtherRunClicked}/>
                    </Row>
                ) : (
                    <Row>
                        <div>There are no other relevant runs in this area.</div>
                    </Row>
                )
                
            }
            

        </Container>
    )
}

export default AreaRunsOverviewPanel;