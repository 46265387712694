import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Stack, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { UserCredentials } from '../classes/UserCredentials';
import { login } from '../api/apiAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CropRows from "../assets/images/crop_rows.jpg";
import { setAccessAndRefreshTokens } from '../Helpers/ServiceWorkerHelper';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [isWorking, setIsWorking] = useState(false);
    const [username, setUsername] = useState("USQ-FAW"); // currently default username to this becuase everyone will use a single account for a start
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [hasLoginError, setHasLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState("");

    const navigate = useNavigate();

    const tryLogin = async () => {
        setIsWorking(true);
        let lCredentials = new UserCredentials(username, password);
        let lResult = await login(lCredentials);

        if (lResult?.status === 200) {
            // Save access token and refresh token in the service worker
            // await send setAccessAndRefreshTokens, so that the tokens have been set in the service worker in the service worker prior to navigating away from this page
            let lMessageResponse = await setAccessAndRefreshTokens(lResult.data.accessToken, lResult.data.refreshToken);

            // Show error message if the access and refresh tokens were unable to be saved by the service worker
            // else navigate to map if successfull
            if (lMessageResponse?.successfull === true) {
                setIsWorking(false);
                navigate('/map');
            }
            else {
                setHasLoginError(true);
                let lErrorMessage = "Something went wrong while attempting to login."
                setLoginErrorMessage(lErrorMessage);
                setIsWorking(false);
            }
        }
        else {
            setIsWorking(false);
            setHasLoginError(true);

            let lErrorMessage = "Invalid username or password."
            if (lResult.response?.data) {
                lErrorMessage = lResult.response?.data;
            }
            setLoginErrorMessage(lErrorMessage);
        }
    }

    return (
        <div id="login-page-container">
            <img src={CropRows} id="login-image-panel" />
            <Container id="login-input-panel">
                <Row style={{ height: "100%" }}>
                    <Col style={{ height: "100%" }}>
                        <Stack gap={3} direction="vertical" style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>

                            <div className='text-center mb-5'>
                                <h1 className="mb-3">Fall Armyworm Detection Tool</h1>
                                <h2>Login</h2>
                            </div>
                            {
                                isWorking === true ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    : null
                            }

                            <Form.Group>
                                <InputGroup>
                                    <Form.Control
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter password..."
                                        style={{ width: "260px" }}
                                    />
                                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Button
                                className="mt-3"
                                variant="primary"
                                onClick={() => tryLogin()}
                                disabled={isWorking}
                                style={{minWidth: "160px"}}
                            >
                                Login
                            </Button>

                            {
                                hasLoginError === true ?
                                    <Alert variant='danger'>{loginErrorMessage}</Alert>
                                    : null
                            }
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default Login;