export class SimpleRunModel {
    id;
    name;
    date;
    infestationPercentage;
    constructor({ id, name, date, infestationPercentage } = {}) {
        this.id = id;
        this.name = name;
        this.date = date;
        this.infestationPercentage = infestationPercentage;
    }

}