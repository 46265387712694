import React, { useState } from "react"
import { Alert, Stack, Button } from "react-bootstrap";

const FAWAlert = ({alertVariant, headingText, bodyHTML, actionButtonText, actionButtonVariant, onActionButtonClick, closeButtonText, closeButtonVariant, dismissable}) => {
    const [show, setShow] = useState(true);

    return (
        <Alert show={show} variant={alertVariant ?? "info"} dismissable={dismissable === true}>
            {
                headingText && headingText.length > 0 ? (
                    <Alert.Heading>{headingText}</Alert.Heading>
                ) : null
            }
            { 
                bodyHTML ? (
                    <>{bodyHTML}</>
                ) : null
            }
            {
                closeButtonText?.length > 0 || actionButtonText?.length > 0 ? (
                    <div className="d-flex justify-content-end">
                        <Stack direction="horizontal">
                            { 
                                closeButtonText?.length > 0 ? (
                                    <Button onClick={() => setShow(false)} variant={closeButtonVariant ?? "info"}>
                                        {closeButtonText}
                                    </Button>
                                ) : null
                            }
                            {
                                actionButtonText?.length > 0 ? (
                                    <Button onClick={(e) => onActionButtonClick ? onActionButtonClick() : e.preventDefault()} variant={actionButtonVariant ?? "info"}>
                                        {actionButtonText}
                                    </Button>
                                ): null
                            }
                            
                        </Stack>
                    </div>
                ) : null
            }
        </Alert>
    )
}

export default FAWAlert;