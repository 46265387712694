
import React, {useEffect, useState} from "react";
import Popover from "../Popover";
import { Container, Row, Col, Stack, Form, Button, InputGroup, Spinner, ProgressBar } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { IsNullOrUndefined, StringIsEmptyNullOrUndefined } from "../../util/common";
import { isDate } from "date-fns";
import { CropTypeOption } from "../../classes/CropTypeOption";
import { FileUploader } from "react-drag-drop-files";
import { RunUploadSubmission } from "../../classes/RunUploadSubmission";
import { postRun, getCropTypes, postUploadImage } from "../../api/apiAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faInfoCircle, faXmark, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import { RunModel } from "../../classes/RunModel";

const validFileTypes = ["JPG"];

const UploadRunPopover = ({onClose, onLoadRun}) => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadedSuccessfully, setIsUploadedSuccessfully] = useState(false);
    const [isUploadFailed, setIsUploadFailed] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [runFiles, setRunFiles] = useState([]);

    const [runName, setRunName] = useState("");
    const [runBatch, setRunBatch] = useState("");
    const [runDate, setRunDate] = useState(new Date());
    const [runLocation, setRunLocation] = useState("");
    const [runCropType, setRunCropType] = useState("");
    const [runNotes, setRunNotes] = useState("");
    const [runResolution, setRunResolution] = useState("");
    const [cropTypeOptions, setCropTypeOptions] = useState([])

    const [uploadedImageFileIDs, setUploadedImageFileIDs] = useState([]);
    const [isImageFilesUploaded, setIsImageFilesUploaded] = useState(false);
    const [isRunNameValid, setIsRunNameValid] = useState(true);
    const [isRunBatchValid, setIsRunBatchValid] = useState(true);
    const [isRunDateValid, setIsRunDateValid] = useState(true);
    const [isRunLocationValid, setIsRunLocationValid] = useState(true);
    const [isRunCropTypeValid, setIsRunCropTypeValid] = useState(true);
    const [isRunFilesValid, setIsRunFilesValid] = useState(true);
    const [isRunResolutionValid, setIsRunResolutionValid] = useState(true);

    const [uploadResultCSSClass, setUploadResultCSSClass] = useState("");

    const [completedRun, setCompletedRun] = useState("");
    const [sizeWarning, setSizeWarning] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(0);


    useEffect(() => {
        resetPageNotifications();
        fetchCropTypes();
    }, [])

    const fetchCropTypes = async() => {
        getCropTypes()
        .then((res, reject) => {
            if (res.status === 200) {
                let cropTypes = [];
                res.data.forEach(d => {
                    cropTypes.push(new CropTypeOption(d.id, d.name));
                })
                setCropTypeOptions(cropTypes);
                setIsLoading(false);
            } else {
                reject("Unexpected response from the server.")
            }
        })
        .catch(err => {
            setError(true);
            setFeedbackMessage("Unexpected response from the server.")
            setIsLoading(false);
        });
    }

    const handleFileUploaderChange = (file) => {
        if (runFiles.length === 0 || runFiles.find(f => f === file) === undefined) {
            let tempRunFiles = [...runFiles];
            tempRunFiles.push(file);
            setRunFiles(tempRunFiles);
        }
    }

    const doClose = () => {
        onClose();
    }

    const resetPageNotifications = () => {
        setFormDisabled(false);
        setIsUploading(false);
        setError(false);
        setFeedbackMessage("");
        setIsUploadFailed(false);
        setIsUploadedSuccessfully(false);
    }

    const doCreateRun = async () => {
        resetPageNotifications();
        
        if (isAllValid()) {

            // Send submission
            setFormDisabled(true);
            setIsUploading(true);
            setUploadProgress(0);
            setFeedbackMessage("Uploading images.")
            
            let imageUploadSuccess = false;
            let targetFolderGuid = null;

            let uploadProgressImageFileIncrements = 50 / runFiles[0].length;

            for (let file in runFiles[0]) {
                if (isNaN(Number(file))) break;
                let fileCount = Number(file) + 1;
                setFeedbackMessage(`Uploading image ${fileCount} of ${runFiles[0].length}`)
                let imgForm = buildImageSubmission(runFiles[0][file], targetFolderGuid ?? "");
                let imgUploadResult = await postUploadImage(imgForm);
                if (imgUploadResult.status !== 200) {
                    setFeedbackMessage("Image upload failed.")
                    imageUploadSuccess = false;
                    break;
                }
                targetFolderGuid = targetFolderGuid ?? imgUploadResult.data.imageFolderGuid;
                imageUploadSuccess = true;
                setUploadProgress((fileCount * uploadProgressImageFileIncrements).toFixed(0));
            }
            
            if (!imageUploadSuccess) {
                setFormDisabled(false);
                setIsUploading(false);
                return;
            }

            let currentUploadProgress = 50;
            let progressUpdateInterval = 1000;
            let progressUpdater = setInterval(() => {
                let newProgressVal = currentUploadProgress + ((100 - currentUploadProgress)/15)
                setUploadProgress(newProgressVal.toFixed(0));
                currentUploadProgress = newProgressVal;
            }, progressUpdateInterval);
            setFeedbackMessage("Processing uploaded images.  This step could take over 5 minutes so keep this browser open until processing is completed")
            let submission = buildRunSubmission(targetFolderGuid);
            let result = await postRun(submission);
            setFormDisabled(false);
            if (result.status === 200) {
                let lCompletedRun = new RunModel(result.data);
                setCompletedRun(lCompletedRun);
                clearFieldValues();
                setIsUploading(false);
                setIsUploadedSuccessfully(true);
                clearInterval(progressUpdater);
                setFeedbackMessage("Processed successfully!")
            } else {
                setIsUploading(false);
                setIsUploadFailed(true);
                clearInterval(progressUpdater);
                setFeedbackMessage("Processing failed.  Please check your provided inputs and try again later.")
            }
        } else {
            setFeedbackMessage("One or more input validation errors occurred.  Please check your input and try again.")
        }
    }

    const   buildRunSubmission = (imageFolderGuid) => {

        let formData = new FormData();
        formData.set("name", runName);
        formData.set("batch", runBatch);
        formData.set("dateOfRun", runDate.toISOString());
        formData.set("location", runLocation);
        formData.set("cropTypeID", Number(runCropType.id));
        formData.set("cropType", runCropType.name);
        formData.set("notes", runNotes);
        formData.set("resolution", Number(runResolution));
        formData.set("targetFolderGuid", imageFolderGuid)

        return formData;
    }

    const buildImageSubmission = (file, targetFolderGuid) => {
        let imgFormData = new FormData();
        imgFormData.set("targetFolderGuid", targetFolderGuid);
        imgFormData.append("image", file);
        return imgFormData;
    }

    const clearFieldValues = () => {
        setRunName("");
        setRunBatch("");
        setRunDate("");
        setRunLocation("");
        setRunCropType("");
        setRunNotes("");
        setRunResolution("");
        setUploadedImageFileIDs("");
    }

    const isAllValid = () => {
        return validateRunName() &&
        validateRunBatch() &&
        validateRunDate() &&
        validateRunLocation() && 
        validateRunCropType() && 
        validateRunResolution() &&
        validateImages();
    }

    const validateRunName = () => {
        let isValid = !StringIsEmptyNullOrUndefined(runName);
        setIsRunNameValid(isValid);
        return isValid;
    }

    const validateRunBatch = () => {
        let isValid = !StringIsEmptyNullOrUndefined(runBatch);
        setIsRunNameValid(isValid);
        return isValid;
    }

    const validateRunDate = () => {
        let isValid = !IsNullOrUndefined(runDate) && isDate(runDate);
        setIsRunDateValid(isValid);
        return isValid;
    }

    const validateRunLocation = () => {
        let isValid = !StringIsEmptyNullOrUndefined(runLocation);
        setIsRunLocationValid(isValid);
        return isValid;
    }

    const validateRunCropType = () => {
        let isValid = runCropType instanceof CropTypeOption && !isNaN(Number(runCropType.id)) && !StringIsEmptyNullOrUndefined(runCropType.name);
        setIsRunCropTypeValid(isValid);
        return isValid;
    }

    const validateImages = () => {
        let isValid = runFiles.length > 0;
        setIsRunFilesValid(isValid);
        return isValid;
    }

    const validateRunResolution = () => {
        let isValid = !StringIsEmptyNullOrUndefined(runResolution) && !isNaN(Number(runResolution));
        setIsRunResolutionValid(isValid);
        return isValid;
    }

    const loadRunInMap = () => {
        if (completedRun instanceof RunModel) {
            onLoadRun(completedRun);
        } else {
            setFeedbackMessage("An unexpected error occurred. Unable to load run into map.")
        }
    }
    return (
        <Popover screenFillPercentage={75} showClose={true} onClose={doClose} header="Upload Run">
            <div className="full-height-container">
                { 
                    isLoading ? 
                    (
                        <div>
                            <Spinner />
                        </div>
                    ) : null
                }

{ 
                    error ? 
                    (
                        <div>
                            An unexpected error occurred while loading this page.  Please try again later.
                        </div>
                    ) : null
                }
                {
                    !isLoading && !error ? (
                        <>
                            <div className="pe-3 mx-0 right-border-divider d-inline-block h-100 w-50" style={{overflowY: 'auto'}}>
                                <Container fluid>
                                    <Row>
                                        <Col xs={12} style={{fontFamily: 'RobotoBold'}}>
                                            Run Information
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={6}>
                                            <Stack direction="vertical">
                                                <div className="field-label">Name</div>
                                                <Form.Control
                                                    disabled={formDisabled}
                                                    isInvalid={!isRunNameValid}
                                                    onChange={(e) => {
                                                        resetPageNotifications();
                                                        setRunName(e.target.value);
                                                    }}
                                                    value={runName}
                                                >

                                                </Form.Control>
                                            </Stack>
                                        </Col>
                                        <Col xs={6}>
                                            <Stack direction="vertical">
                                                <div>Batch</div>
                                                <Form.Control
                                                    disabled={formDisabled}
                                                    isInvalid={!isRunNameValid}
                                                    onChange={(e) => {
                                                        resetPageNotifications();
                                                        setRunBatch(e.target.value);
                                                    }}
                                                    value={runBatch}
                                                >
                                                    
                                                </Form.Control>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <Stack direction="vertical">
                                                <div>Date of run</div>
                                                <ReactDatePicker 
                                                    disabled={formDisabled}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    showIcon={true}
                                                    selected={runDate}
                                                    onChange={(date) => {
                                                        resetPageNotifications();
                                                        setRunDate(date);
                                                    }}
                                                />
                                            </Stack>
                                        </Col>
                                    
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <Stack direction="vertical">
                                                <div>Location</div>
                                                <Form.Control
                                                    disabled={formDisabled}
                                                    isInvalid={!isRunLocationValid}
                                                    onChange={(e) => {
                                                        resetPageNotifications();
                                                        setRunLocation(e.target.value);
                                                    }}
                                                    value={runLocation}
                                                >

                                                </Form.Control>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <Stack direction="vertical">
                                                <div>Crop Type</div>
                                                <Form.Select
                                                    disabled={formDisabled}
                                                    isInvalid={!isRunCropTypeValid}
                                                    selected={runCropType}
                                                    onChange={(e) => {
                                                        resetPageNotifications();
                                                        let selectedCropType = cropTypeOptions.find(cto => Number(cto.id) === Number(e.target.value))
                                                        setRunCropType(selectedCropType);
                                                    }}
                                                >
                                                    <option value="empty">Select Crop Type</option>
                                                    {
                                                        cropTypeOptions.map((cto, idx) => {
                                                            return (
                                                                <option key={`cto_${idx}`} value={cto.id}>{cto.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <Stack direction="vertical">
                                                <div>Notes</div>
                                                <Form.Control 
                                                    disabled={formDisabled}
                                                    style={{resize: "none"}}
                                                    as="textarea" 
                                                    rows={5} 
                                                    value={runNotes} 
                                                    onChange={(e) => {
                                                        resetPageNotifications();
                                                        setRunNotes(e.target.value);
                                                    }}
                                                />
                                            </Stack>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="mt-5">
                                    <Stack direction="horizontal" gap={3}>
                                        <Button
                                            disabled={formDisabled}
                                            className="btn-primary"
                                            onClick={() => doCreateRun()}
                                        >
                                            Create Run
                                        </Button>
                                        <Button
                                            className="btn-outline-secondary"
                                            onClick={() => onClose()}
                                        >
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Container>
                            </div>
                            <div className="ps-3 d-inline-block w-50 h-100" style={{overflowY: "auto"}}>
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            Images to Analyse
                                            {sizeWarning && <div>Selected files exceed maximum allowed size!</div>}
                                        </Col>
                                        <Col xs={12}>
                                            <FileUploader 
                                                disabled={formDisabled}
                                                classes="run-file-uploader"
                                                style={{margin: "auto"}}
                                                handleChange={handleFileUploaderChange}
                                                name="file"
                                                label="Upload or drag files here"
                                                multiple={true}
                                                selected={runFiles}
                                                types={validFileTypes}
                                                isInvalid={!isRunFilesValid}
                                                maxSize="100"
                                                onSizeError={() => { setSizeWarning(true) }}
                                            />
                                        </Col>
                                    </Row>

                                    <Col xs={12} className="mt-4">
                                        Resolution
                                    </Col>
                                    <Row className="mt-1">
                                        <Col xs={6}>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    disabled={formDisabled}
                                                    value={runResolution}
                                                    onChange={(e) => setRunResolution(e.target.value)}
                                                />
                                                <InputGroup.Text>m<sup>2</sup></InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div>
                                                <Stack direction="horizontal" className="p-2" style={{border: "2px dashed #FFB44899", fontSize: "14px"}}>
                                                    <FontAwesomeIcon icon={faInfoCircle} className="large-icon mx-3 mb-auto mt-2"/>
                                                    <div>
                                                        <div className="mb-1 mt-2 pe-2">Resolution determines the size of each area that is processed.  It is recommended that the following resolutions are used:</div>
                                                        <ul>
                                                            <li>25m<sup>2</sup> for low resolution (faster processing)</li>
                                                            <li>10m<sup>2</sup> for medium resolution</li>
                                                            <li>2m<sup>2</sup> for high resolution (slower processing)</li>
                                                        </ul>
                                                    </div>
                                                </Stack>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                    
                                    {
                                        isUploading ? (
                                            <Row className="mt-1">
                                                <Col xs={12}>
                                                    <div className="text-center pt-5 w-75 mx-auto">
                                                        <ProgressBar animated={true} striped={true} now={uploadProgress} label={`${uploadProgress}%`} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : null
                                    }
                                    {
                                        isUploadedSuccessfully ? (
                                            <Row className="mt-1">
                                                <Col xs={12}>
                                                    <div className="text-center pt-5">
                                                        <FontAwesomeIcon className={`upload-result-icon ${uploadResultCSSClass}`} icon={faCheckCircle} color="green"/>
                                                        <br/>
                                                        <Button
                                                            className="btn-primary mt-2"
                                                            onClick={() => loadRunInMap()}
                                                        >
                                                            View Run on Map
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : null
                                    }
                                    {
                                        isUploadFailed ? (
                                            <Row className="mt-1">
                                                <Col xs={12}>
                                                    <div className="text-center pt-5">
                                                        <FontAwesomeIcon className={`upload-result-icon ${uploadResultCSSClass}`} icon={faXmarkSquare} color="red"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : null
                                    }
                                    {
                                        feedbackMessage.length > 0 ?
                                        (
                                            <Row>
                                                <Col xs={12}>
                                                    <div className="text-center pt-1">
                                                        <div className="mx-auto text-center">{feedbackMessage}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) :null
                                    }

                                </Container>
                            </div>
                        </>
                    ) :null
                }
            </div>
        </Popover>
    )
}

export default UploadRunPopover;