
export class RunOverviewModel {
    id;
    name;
    batch;
    dateOfRun;
    town;
    cropTypeID;
    cropType;
    resolution;
    notes;

    constructor({id, name, batch, date, nearestTown, cropTypeID, cropType, resolution, notes}) {
        this.id = id;
        this.name = name;
        this.batch = batch;
        this.dateOfRun = new Date(date);
        this.town = nearestTown;
        this.cropTypeID = cropTypeID;
        this.cropType = cropType;
        this.resolution = resolution;
        this.notes = notes;
    }
}