import { requests } from "./apiAgent";
import { getRefreshToken, setAccessAndRefreshTokens } from "../Helpers/ServiceWorkerHelper";

const getAPI = async (url) => {
  let results = await requests
    .get(url)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return results;
};

const postAPI = async (url, data = {}, config = {}, ttl = 1) => {
  let result = await requests
    .post(url, data, config, ttl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return result;
}

export const refreshAccessToken = async () => {
  let repeatRequestCount = 0;

  // get existing refresh token from the service worker
  let lRefreshTokenResult = await getRefreshToken();

  if (lRefreshTokenResult && lRefreshTokenResult.successfull === true) {
    let result = await requests
      .post("/auth/refreshaccesstoken", { RefreshToken: lRefreshTokenResult.result }, {}, repeatRequestCount)
      .then(async (res) => {
        if (res?.status === 200 && res?.data) {
          // Update access and refresh tokens in the service worker
          let lResponse = await setAccessAndRefreshTokens(res.data?.accessToken, res.data?.refreshToken);
          
          if(lResponse.successfull === true){
            return { success: true };
          }
          else{
            return { success: false };
          }
        }
        else {
          return { success: false };
        }
      })
      .catch((err) => {
        return { success: false, error: err };
      });
    return result;
  }
  else { // can't get refresh token
    return null;
  }
}

export const login = async (userCredentials) => await postAPI("/auth/login", userCredentials, {}, 0);
export const getRun = async (id) => await getAPI(`/runs/${id}`);
export const getRuns = async () => await getAPI(`/runs/find`);
export const getRelevantRuns = async (targetRunID) => await getAPI(`/runs/relevant/${targetRunID}`);
export const getMarkerImagery = async (siteID) => await getAPI(`/imagery/runsites/${siteID}`);

export const postRun = async (uploadSubmission) => await postAPI(`/runs/create`, uploadSubmission, {maxContentLength: 104857600, maxBodyLength: 104857600})
export const postUploadImage = async (imgForm) => await postAPI("/runs/image", imgForm, {maxContentLength: 104857600, maxBodyLength: 104857600});

export const getCropTypes = async () => await getAPI("/crops/all");