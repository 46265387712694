import React, { useRef, useState, useEffect } from 'react';
import {renderToString} from "react-dom/server";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CustomMapMarker } from '../classes/CustomMapMarker';
import { Spinner } from 'react-bootstrap';
import { getMarkerImagery } from '../api/apiAccess';
import { StringIsEmptyNullOrUndefined } from '../util/common';

const FAWMap = ({ runSites, showOnlyInfected, refCount, reinitializeZoom, reinitializeCenter, hideZoomControls }) => {
    const ref = useRef();
    const defaultMapCenter = new window.google.maps.LatLng(-36.7596, 144.2786);
    const [googleMap, setGoogleMap] = useState("");
    const [markers, setMarkers] = useState([]);
    const [currentZoom, setCurrentZoom] = useState("");

    // Maintain a list of markers that are currently rendered to the map so we can remove them before
    // rerendering/repopulating the map
    const [renderedMarkers, setRenderedMarkers] = useState([]);

    // Only re-render when innerRefCount != refCount.  This prevents rerendering every time
    // any state changes in the parent component. Re-render only occurs if the parent component
    // explicitly changes the refCount prop (i.e. it wants the map to update).
    const [innerRefCount, setInnerRefCount] = useState(0);

    useEffect(() => {
        let mapOptions = {
            streetViewControl: false,
            mapTypeControl: false,
            mapTypeId: window.google.maps.MapTypeId.SATELLITE,
            disableDefaultUI: true,
            zoomControl: !hideZoomControls,
            zoomControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_BOTTOM,
            },
            tilt: 0
        }
        let map = new window.google.maps.Map(ref.current, mapOptions);
        setCurrentZoom("");
        map.setCenter(defaultMapCenter);
        map.setZoom(14);
        setGoogleMap(map);
    }, []);

    useEffect(() => {
        setCurrentZoom("");
    }, [reinitializeCenter, reinitializeZoom])

    useEffect(() => {
        if (!googleMap || googleMap === "") return;
        googleMap.setOptions({zoomControl: !hideZoomControls});
    },
    [hideZoomControls])

    useEffect(() => {
        if (!runSites) return;
        let markers = runSites?.filter(rs => showOnlyInfected ? rs.infected : true).map(s => new CustomMapMarker({siteID: s.id, latitude: s.latitude, longitude: s.longitude, scale: 0.5, title: `${s.infected ? "FAW Detected" : "FAW Not Detected"} - [${s.latitude.toFixed(6)}, ${s.longitude.toFixed(6)}]`, color: s.infected ? "#FF0000" : "#ABABAB", data: s})).filter(m => m.isValidGeoLocation());
        setMarkers(markers);
    }, [runSites])

    useEffect(() => {
        if (googleMap === "") return;
        if (innerRefCount === refCount) return; // Only re-render if the refCount prop has changed
        if (googleMap) setCurrentZoom(googleMap.getZoom());
        setUpMarkers();
        setInnerRefCount(refCount);
    }, [googleMap, markers])

    useEffect(() => {

        if (googleMap) setCurrentZoom(googleMap.getZoom());
        if (showOnlyInfected === true) {
            renderedMarkers.forEach(m => {
                if (m.customMarkerData.infected !== true) {
                    m.setVisible(false)
                }
            })

        } else {
            renderedMarkers.forEach(m => m.setVisible(true));
        }
    }, [showOnlyInfected])

    const setUpMarkers = () => {
        if (currentZoom === "") {
            let sumLat = markers.reduce((a, b) => a + b.Latitude, 0);
            let sumLng = markers.reduce((a, b) => a + b.Longitude, 0);
            let avgLatLng = new window.google.maps.LatLng(sumLat / markers.length, sumLng / markers.length);
            if (!isNaN(avgLatLng.lat()) && !isNaN(avgLatLng.lng())) {
                googleMap.setCenter(avgLatLng);
            }
        }
        let bounds = new window.google.maps.LatLngBounds();
        let markerIcon = faXmark;
        let tempRenderedMarkers = [];
        renderedMarkers.forEach(m => m.setMap(null));
        let info = new window.google.maps.InfoWindow({
            content: "",
            map: googleMap
        });
        
        let targetMarker = null;

        // Add the markers to the map
        markers.forEach(marker => {
            let lMarker = marker.getGoogleMapsMarker(googleMap, markerIcon);
            // Open/close the InfoWindow for this marker when it is clicked
            window.google.maps.event.addListener(lMarker, 'click', async () => {
                await openMarkerInfo(marker, info, lMarker);
            });

            tempRenderedMarkers.push(lMarker);    
        });

        if (currentZoom === "") {
            // Expand the view to show all markers on the map
            tempRenderedMarkers.forEach(trm => {
                bounds.extend(trm.position);
                googleMap.fitBounds(bounds);
            });
            
            googleMap.setZoom(17);
        }

        setRenderedMarkers(tempRenderedMarkers);
    }

    const openMarkerInfo = async (marker, info, lMarker) => {
        if (marker.hasImagery()) {
            let content = getInfoWindowDataRender(marker.Imagery.data, marker.Latitude, marker.Longitude);
            info.setContent(content);
        } else {
            // Set the content as a spinne while calling the api for the image
            let content = renderToString(<Spinner animation="grow"/>)
            info.setContent(content);

            await fetchMarkerImagery(marker, info);

            // Set the content to the results of the api call
            info.setContent(content);
        }

        info.open(googleMap, lMarker);
    }

    const fetchMarkerImagery = async (marker, infoWindow) => {

        let siteID = marker.getSiteID();
        getMarkerImagery(siteID)
            .then(res => {
                 // update the imagery for the marker
                marker.Imagery = res;
                let content = getInfoWindowDataRender(res.data, marker.Latitude, marker.Longitude);
                infoWindow.setContent(content);
            })
            .catch(err => {
                // update the infowindow to inform user of no imagery
                let content = getInfoWindowDataRender();
                infoWindow.setContent(content);
                console.log(err)
            });
    }

    const getInfoWindowDataRender = (imageBase64String, latitude, longitude) => {
        let imgComponent = null;
        let locationComponent = null;

        if (!StringIsEmptyNullOrUndefined(imageBase64String)) {
            imgComponent = (<div className="mx-auto text-center mx-3 my-2"><img src={`data:image/jpeg;base64,${imageBase64String}`} className="marker-tooltip-imagery"/></div>);
        }

        if (latitude && longitude) {
            locationComponent = (<div>
                                    <div className="text-center mb-1">Lat: {latitude}</div> 
                                    <div className="text-center mb-2">Lng: {longitude}</div>
                                </div>)
        }

        return renderToString(
            <div>
                {imgComponent ?? <Spinner animation="grow" />}
                {locationComponent}
                {imgComponent === null && locationComponent === null ? <div>No imagery available</div> : null}
            </div>
        )
    }

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <div ref={ref} id="map" style={{ height: '100vh', width: '100%' }} />
        </div>
    );
}

export default FAWMap;