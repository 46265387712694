import React from "react";
import { Badge, Table, Button } from "react-bootstrap";
import format from "date-fns/format";

const SimpleRunsInfo = ({runs, showOpenButton, onOpenRun}) => {
    return (
        <Table className="map-run-overview-table">
            <thead>
                <tr>
                    <th style={{width: "100px", minWidth: "100px", maxWidth: "100px"}}>Name</th>
                    <th>Date</th>
                    <th>Infestation %</th>
                    
                </tr>
            </thead>
            <tbody>
                {runs?.map((run, idx) => {
                    return (
                        <tr key={`run_${run.name}_${idx}`}>
                            <td><div className="d-inline-block me-2">{run?.name}</div>{showOpenButton ? <Button className="badge-button" onClick={() => onOpenRun(run)}>Open</Button> : null}</td>
                            <td>{run?.date ? format(run?.date, "dd-MM-yyyy") : ""}</td>
                            <td>{run?.infestationPercentage?.toFixed(2)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

export default SimpleRunsInfo;