import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";


const Popover = ({screenFillPercentage, showClose, onClose, header, children}) => {
    return (
        <div className="sqv-popover" style={{ minWidth: `${screenFillPercentage}vw`, minHeight: `${screenFillPercentage}vh`, top: `calc(50vh - calc(${screenFillPercentage}vh/2))`, left: `calc(50vw - calc(${screenFillPercentage}vw/2))`}}>
            { 
                showClose || (header && header.length > 0) ? 
                (
                    <div className="px-5 py-4">
                        <div className="d-inline-block w-75">
                            {
                                header && header.length > 0 ? 
                                (
                                    <div className="ps-2 sqv-secondary-header-text">{header}</div>
                                ) : null
                            }
                        </div>
                        <div className="d-inline-block w-25" style={{textAlign: "right"}}>
                            {
                                showClose ? (
                                    <Button 
                                        className="btn-transparent-light"
                                        onClick={() => onClose()}
                                        >
                                        <FontAwesomeIcon icon={faXmark} size="lg"/>
                                    </Button>       
                                ) : null
                            }
                        </div>
                    </div>

                ) : null    
            }
            
            <div className="px-5 py-4">
                {children}
            </div>

        </div>
    )
}

export default Popover;