import { useState, useEffect } from "react";
import { isLoggedIn } from "../Helpers/ServiceWorkerHelper";
import { Navigate } from "react-router-dom";
import Loading from "../pages/Loading";

// Wraps a react Route component, and kicks the user back to the login page of they do not have the appropriate permission to access the page
const RestrictedRoute = ({ children }) => {
    // value indicating if user access to the route
    // default to null - if we default to false the page will navigate back to the login page prior to getting the logged in status from the service worker
    // this way while we are waiting for the status to return the user can see a loading indicator and wil then be taken to the correct page once we have the result (should be quick)
    const [hasPermission, setHasPermission] = useState(null);

    useEffect(() => {
        isLoggedIn().then((loggedIn) => {
            // One we update the state, the page will re render taking the user away from the loading page to the actual page they requested
            setHasPermission(loggedIn);
        })
    }, [])

    if (hasPermission !== null) {
        if (hasPermission === true) {
            return children;
        }
        else {
            return <Navigate to="/" />;
        }
    }
    else {
        return <Loading />; // show a loading indicator while we are checking if the user is logged in
    }
}
export default RestrictedRoute;