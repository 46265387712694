// This is is a wrapper around navigator.serviceWorker.controller.postMessage
// which allows you to retrieve values from the serviceworker without having to listen to an event on the react side
// In other words it makes them run syncronously
// If you want it to be async call postmessage directly and listen for a message event on the page as a respone navigator.serviceWorker.controller.postMessage
// https://stackoverflow.com/questions/30177782/chrome-serviceworker-postmessage
import { ServiceWorkerSendMessageResponse } from "../classes/ServiceWorkerSendMessageResponse";

export const isLoggedIn = async () => {
    let lMessageResponse = await sendMessage({ action: 'GET_ACCESS_TOKEN' });

    // If for some reason the message respone fails, set isloggged in to false
    if (lMessageResponse && lMessageResponse.successfull === true && lMessageResponse.result !== "" && lMessageResponse.result !== null && lMessageResponse !== undefined) {
        return true;
    }
    else{
        return false;
    }  
}

export const getAccessToken = async () => {
    let lMessageResponse = await sendMessage({ action: 'GET_ACCESS_TOKEN' });
    return lMessageResponse;
}

export const getRefreshToken = async () => {
    let lMessageResponse = await sendMessage({ action: 'GET_REFRESH_TOKEN' });
    return lMessageResponse;
}

export const setAccessAndRefreshTokens = async (accessToken, refreshToken) => {
    let lMessageResponse = await sendMessage(
        {
            action: 'SET_ACCESS_AND_REFRESH_TOKEN',
            accessToken: accessToken,
            refreshToken: refreshToken
        }
    );
    return lMessageResponse;
}

export const clearAccessAndRefreshTokens = async () => {
    let lMessageResponse = await sendMessage({ action: 'CLEAR_ACCESS_AND_REFRESH_TOKEN' }
    );
    return lMessageResponse;
}

const sendMessage = (message) => {
    // This wraps the message posting/response in a promise, which will
    // resolve if the response doesn't contain an error, and reject with
    // the error if it does. If you'd prefer, it's possible to call
    // controller.postMessage() and set up the onmessage handler
    // independently of a promise, but this is a convenient wrapper.
    return new Promise(function (resolve, reject) {
        var messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = function (event) {
            console.log(event);
            if (!event.data || event.data.error) {
                let lResponse = new ServiceWorkerSendMessageResponse(false, event.data.error);
                reject(lResponse);
            } else {
                let lResponse = new ServiceWorkerSendMessageResponse(true, event.data);
                resolve(lResponse);
            }
        };
        // This sends the message data as well as transferring
        // messageChannel.port2 to the service worker.
        // The service worker can then use the transferred port to reply
        // via postMessage(), which will in turn trigger the onmessage
        // handler on messageChannel.port1.
        // See
        // https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
        navigator.serviceWorker?.controller?.postMessage(message, [messageChannel.port2]);
    });
}