import Axios from "axios";
import { apiConfig } from "./apiConfig";
import globalRouter from "../Helpers/GlobalRouter";
import { setupCache } from "axios-cache-adapter";
import { refreshAccessToken } from "./apiAccess";
import { clearAccessAndRefreshTokens, getAccessToken, sendMessage } from "../Helpers/ServiceWorkerHelper";

// See https://github.com/lisaogren/axios-cache-adapter for configuration options
const cacheOptions = {
    maxAge: 15 * 60 * 1000, // 15 minutes
    limit: false, // No limit to cached requests
    exclude: {
        paths: [],
        query: true, // Doesn't cache requests that have a query string component
        filter: null,
        methods: ['post', 'patch', 'put', 'delete'] // Doesn't cache requests using these methods
    },
    clearOnStale: false, // Clear cached results as soon as they become stale
    clearOnError: true, // Reset the whole cache if there are any errors writing to the cache
    readOnError: false,
    readHeaders: true, // Use the cache-control headers returned by the API to set the max-age of the cache
    ignoreCache: false,
    debug: process.env.REACT_APP_ENV !== "PRODUCTION" ? true : false // Console log the caching functions in dev
}

// Create a cache and adapter that can be passed to the Axios instance
const cache = setupCache(cacheOptions);

export const clearAPICache = () => {
    cache.store.clear();
}

let refreshingFunc = undefined;

export const apiagent = (options, ttl = 1) => {
    let retry = true;

    // Define request success interceptor
    const prepareRequestHandler = async(requestConfig) => {
        if (options.config && options.config.headers) {
            let lKeys = Object.keys(options.config.headers);
            for (const key of lKeys) {
                requestConfig.headers[key] = options.config.headers[key];
            }
        }
        if (requireAuthHeader(requestConfig.url)) {
            let lAccessTokenResponse = await getAccessToken();
            let lAccessToken = null;
            if(lAccessTokenResponse && lAccessTokenResponse.successfull === true){
                lAccessToken = lAccessTokenResponse.result;
            }
            requestConfig.headers["Authorization"] = "Bearer " + lAccessToken;
        }

        requestConfig.headers["x-api-key"] = "8f636c0ee7b749889b361d1ad9a0e3d3";
        return requestConfig;
    };

    // Define request error interceptor
    const requestErrorHandler = (requestError) => {
        console.log("Request Error:", requestError);
        Promise.reject(requestError);
    }

    // Define response success interceptor
    const responseSuccessHandler = async (response) => {
        console.log("Response success: ", response);
        const length = await cache.store.length()
        console.log('Cache store length:', length)
        return response
    }

    // Define response error interceptor
    const onResponseError = () => {
        clearAPICache();
        // Log the user out
        clearAccessAndRefreshTokens();
        // Navigate to the login page
        globalRouter.navigate("/");
    }

    const responseErrorHandler = async (error) => {
        console.log("Response Error: ", error);
        if (error?.response?.status === 401 && retry === true && ttl > 0) {
            // Try again once after the first failure
            retry = false;
            try {
                // If there is not already a refresh request underway, create one
                // try and request a new access token
                if (!refreshingFunc) refreshingFunc = refreshAccessToken();

                // Await the results of the currently underway refresh request
                let results = await refreshingFunc;
                
                if (results?.success === true) {
                    // The refresh token successfully acquired a new access token and have saved it in the service worker
                    // Run the original request again
                    return request(options).then(onRequestSuccess).catch(onRequestError);
                } else {
                    onResponseError();
                }
            } catch (err) {
                // Some other error occurred, return to login
                onResponseError();
            } finally {
                // Clear/reinitialize the refresh request function
                refreshingFunc = undefined;
            }
        }

        refreshingFunc = undefined;

        return Promise.reject(error);
    }

    // Create an Axios instance that uses the cache adapter 
    const request = Axios.create({adapter: cache.adapter});
    // Attach our custom request and response interceptors
    request.interceptors.request.use(prepareRequestHandler, requestErrorHandler);
    request.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

    // Perform the request
    return request(options).then(onRequestSuccess).catch(onRequestError).finally(onRequestFinished(options));;
};

const onRequestSuccess = (response) => {
    return Promise.resolve(response);
};

const onRequestError = async (error) => {
    return Promise.reject(error);
};

const onRequestFinished = (options) => {
    if (process.env.REACT_APP_ENV === "PRODUCTION") return;
    console.log("Request completed:", options); // Debug log
}

/**
 * Base HTTP Client
 */
export const requests = {
    get(url, params = {}) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "get",
                params: params
            }
        );
    },

    delete(url) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "delete",
            }
        );
    },

    post(url, data = {}, config = {}, ttl = 1) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "post",
                data: data,
                config: config,
                headers: { "Content-Type" : undefined}
            },
            ttl
        );
    },

    put(url, data = {}) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "put",
                data: data,
            }
        );
    },

    patch(url, data = {}) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "patch",
                data: data,
            }
        );
    },
};


const requireAuthHeader = (path) => {
    let lRequireAuthHeader = true;

    let lAllowAnonymousPaths = apiConfig.allowAnonymousPaths;

    if (lAllowAnonymousPaths.includes(path) === true) {
        lRequireAuthHeader = false
    }

    return lRequireAuthHeader;
} 
