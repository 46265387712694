import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import globalRouter from "../Helpers/GlobalRouter";
import React from "react";
import Login from "../pages/Login";
import Map from "../pages/Map";
import RestrictedRoute from "./RestrictedRoute";

const CustomRoutes = () => {
    // Expose the navigate functionality to non-react components by storing it
    // in the GlobalRouter helper
    const navigate = useNavigate();
    globalRouter.navigate = navigate;

    return (
        <Routes>
            <Route path="/" element={<Login />} />

            <Route path="/map" element={
                <RestrictedRoute><Map /></RestrictedRoute>
            } />

            {/*
             DEFAULT PATH - if the current path does not match any routes path above this, this route is used 
             Navigate to the map page, since the map route is wrapped in a RestrictedRoute component, if the user is not logged in
             they will be redirected to the login page
             */}
            <Route path="*" element={
                <Navigate to="/map" />
            } />
        </Routes>
    )
}
export default CustomRoutes;