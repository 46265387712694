import React, { useRef } from "react";
import { Container, Stack, Button, Row, Col, ListGroup } from 'react-bootstrap';
import Popover from '../Popover';
import USQLogo from '../../assets/images/unisq-logo.svg';
import DAFLogo from '../../assets/images/queensland-government-logo.png';
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Mailto from "../Mailto";

const LandingPopover = ({onUploadRunClicked, onFindRunClicked}) => {
    const uploadRunRef = useRef();
    const isLarge = useMediaQuery({query: '(min-width: 1200px)'});

    return (
        <Popover screenFillPercentage={isLarge ? 80 : 95}>
            <div className="full-height-container" style={{top: "0px"}}>
                <div className="pe-3 mx-0 d-inline-block h-100" style={{overflowY: 'auto'}}>
                    <Container fluid>
                        <Row className="mt-1">
                            <Col xs={12}>
                                <Stack direction="horizontal" className="sqv-popover-header sqv-primary-header-text mt-4">
                                    <img src={DAFLogo} height="70px" />
                                    <img className="ms-3" src={USQLogo} height="70px" />
                                    <div className="ms-3">Fall Armyworm Detection Tool</div>
                                </Stack>    
                            </Col>
                        </Row>

                        <Row className="mt-5 pt-3">
                            <Col xs={12} className="sqv-popover-body">
                                <div className="px-3">
                                    <div className="mb-3">Developed by the University of Southern Queensland’s Centre for Agricultural Engineering and Queensland Department of Agriculture and Fisheries (DAF). Contact: <Mailto email="mccarthy@usq.edu.au" subject="Fall Armyworm Detection Tool Inquiry">mccarthy@usq.edu.au</Mailto></div>
                                    <div>The webtool requires UAV data collected with the protocol below:
                                        <ul>
                                            <li>camera pointed straight down (-90&deg;)</li>
                                            <li>30 m flight altitude</li>
                                            <li>speeds &lt; 2 m/s or 7.2 km/hr to avoid image blurring</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12}>
                                <div className="mb-1">To save processing time, compress your UAV images before you upload them as follows:</div>
                                <ol>
                                    <li>Download and install the program FastStone Photo Resizer at <a href="https://www.faststone.org/FSResizerDetail.htm" target="_blank">https://www.faststone.org/FSResizerDetail.htm</a></li>
                                    <li>Use FastStone Photo Resizer as follows:
                                        <ol type="a">
                                            <li>Select the Source Folder with UAV images by pressing button '...' and press 'Add All'.</li>
                                            <li>Press 'Settings' and select 15% for the quality and uncheck 'Use JPEG quality from the original file if possible'.</li>
                                            <li>Press OK, check the output folder and press 'Convert'.</li>
                                        </ol>

                                    </li>
                                    
                                    <li>Upload these resized images to <a href="/map">fawtool.com.au</a></li>
                                </ol>
                            </Col>
                        </Row>

                        <Row className="mt-5 pt-3">
                            <Col xs={12}>
                                <Stack direction="horizontal" gap={5} className="sqv-popover-action">
                                    <Button
                                        className="btn-primary btn-lg"
                                        style={{width: "200px"}}
                                        ref={uploadRunRef}
                                        onClick={() => {
                                            onUploadRunClicked()
                                        }}
                                    >
                                        Upload a Run
                                    </Button>
                                    <Button
                                        className="btn-primary btn-lg"
                                        style={{width: uploadRunRef?.current?.clientWidth, height: uploadRunRef?.current?.clientHeight}}
                                        onClick={() => onFindRunClicked()}
                                    >
                                        Find a Run
                                    </Button>
                                </Stack>   
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        </Popover>
    )
}

export default LandingPopover;