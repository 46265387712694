import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import format from "date-fns/format";

const RunPanelContents = ({name, batch, dateOfRun, location, cropType, resolution, notes}) => {

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    Name
                </Col>
                <Col xs={12}>
                    <Form.Control
                        disabled={true}
                        value={name}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    Batch
                </Col>
                <Col xs={12}>
                <Form.Control
                        disabled={true}
                        value={batch}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    Date of run
                </Col>
                <Col xs={12}>
                    <Form.Control
                        disabled={true}
                        value={format(dateOfRun, "dd/MM/yyyy")}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    Location
                </Col>
                <Col xs={12}>
                    <Form.Control
                        disabled={true}
                        value={location}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    Crop Type
                </Col>
                <Col xs={12}>
                    <Form.Control
                        disabled={true}
                        value={cropType}
                    />  
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    Resolution
                </Col>
                <Col xs={12}>
                    <Form.Control
                        disabled={true}
                        value={resolution}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12}>
                    Notes
                </Col>
                <Col xs={12}>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        disabled={true}
                        value={notes}
                        style={{resize: "none"}}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default RunPanelContents;