import React, { useEffect, useState, useRef } from "react";
import { Container, Form } from "react-bootstrap";

const MapFilteringOptions = ({onFilteringChanged}) => {
    const [filterState, setFilterState] = useState({infectedOnly: false});
    const switchRef = useRef();

    useEffect(() => {
        onFilteringChanged(filterState);
    }, [filterState]);

    const onFilterChanged = (newState) => {
        let tempState = {...newState};
        setFilterState(tempState);
    }

    const removeFocus = (ref) => {
        ref.current.blur();
    }
    
    return (
        <div>
            <Form className="map-filter-option">
                <Form.Check 
                    ref={switchRef}
                    type="switch"
                    id="infected-sites-switch"
                    label="Show only infected sites"
                    onChange={(e) => {
                        if (e.target.checked === filterState.infectedOnly) return;
                        let tempState = filterState;
                        tempState.infectedOnly = e.target.checked;
                        onFilterChanged(tempState);
                    }}
                    onFocus={() => {
                        removeFocus(switchRef);
                    }}

                />
            </Form>
        </div>
    )
}

export default MapFilteringOptions;