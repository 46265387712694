import React, { useRef, useState } from 'react';
import { Container, Stack, Button, Spinner} from 'react-bootstrap';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import FAWMap from '../components/FAWMap';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import RunPanelContents from '../components/RunPanelContents';
import AreaRunsOverviewPanel from '../components/AreaRunsOverviewPanel';
import { SimpleRunModel } from '../classes/SimpleRunModel';
import { RunModel } from '../classes/RunModel';
import USQLogoAcronymWhite from '../assets/images/unisq-logo-acronym-white.png';
import QLDGovLogoWhite from '../assets/images/queensland-government-logo-white.png';
import LandingPopover from '../components/Popovers/LandingPopover';
import UploadRunPopover from '../components/Popovers/UploadRunPopover';
import FindRunPopover from '../components/Popovers/FindRunPopover';
import LearnMorePopover from '../components/Popovers/LearnMorePopover';
import MapFilteringOptions from '../components/MapFilteringOptions';
import { getRelevantRuns } from '../api/apiAccess';
import { Resolution, Margin, usePDF } from "react-to-pdf";

const mapRender = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
};

const Map = () => {
    const runInfoPanelRef = useRef();
    const runOverviewPanel = useRef();
    const [showRunInfoPanel, setShowRunInfoPanel] = useState(true);
    const [insetOverviewPanel, setInsetOverviewPanel] = useState(true);
    const [showOpenRunInfoButton, setShowOpenRunInfoButton] = useState(false);
    const [mapRenderCount, setMapRenderCount] = useState(1);
    const [currentSimpleRun, setCurrentSimpleRun] = useState([new SimpleRunModel(1, "Run 1", new Date(2023, 5, 9), 17)]);
    const [currentDetailedRun, setCurrentDetailedRun] = useState(new RunModel())
    const [showOnlyInfected, setShowOnlyInfected] = useState(false);

    const [showMainPopover, setShowMainPopover] = useState(true);
    const [showUploadRunPopover, setShowUploadRunPopover] = useState(false);
    const [showFindRunPopover, setShowFindRunPopover] = useState(false);
    const [enableMapUI, setEnableMapUI] = useState(false);

    const [otherRelevantRuns, setOtherRelevantRuns] = useState([])

    const [hideMapZoomControls, setHideMapZoomControls] = useState(false);
    const [showPDFCaptureProgress, setShowPDFCaptureProgress] = useState(false);
    const [hideNonPDFUI, setHideNonPDFUI] = useState(false);

    // Map reset properties
    const [resetZoom, setResetZoom] = useState(0);
    const [resetCenter, setResetCenter] = useState(0);

    const { toPDF, targetRef } = usePDF({
        filename: "map.pdf",
        page: { margin: Margin.SMALL ,orientation: 'landscape',},
        overrides: {
            pdf: {
                compress: true
            }
        }
      });

    const togglePanel = () => {
        setShowRunInfoPanel((previousState) => !previousState);
        setInsetOverviewPanel((previousState) => !previousState);
    }

    const toggleDownloadPDF = () => {
        //setEnableMapUI(false);
        setHideNonPDFUI(true);       
        setHideMapZoomControls(true);
        setShowPDFCaptureProgress(true);
        setTimeout(() => {setShowPDFCaptureProgress(false)}, 800);
        setTimeout(()=>{toPDF();},1000);
        setTimeout(()=>{resetMapUI();},1100);
    }

    const loadSelectedRun = (run) => {
        setCurrentDetailedRun(run);
        let lSimpleRun = run.getSimpleRunModel();
        setCurrentSimpleRun(lSimpleRun);
        resetMapZoomAndCenter();
        resetMapUI();
        setMapRenderCount(mapRenderCount + 1);
        populateOtherRelevantRuns(run);
        setShowMainPopover(false);
    }

    const resetMapZoomAndCenter = () => {
        setResetCenter(resetCenter + 1);
        setResetZoom(resetZoom + 1);
    }

    const resetMapUI = () => {
        setHideNonPDFUI(false);
        setShowPDFCaptureProgress(false);
        setHideMapZoomControls(false);
        setEnableMapUI(true);
        setInsetOverviewPanel(true);
        setShowRunInfoPanel(true);
    }

    const populateOtherRelevantRuns = async (focusedRun) => {
        getRelevantRuns(focusedRun.overview.id).then(res => {
            if (res.status === 200) {
                let relevantRuns = res.data.map(r => new RunModel(r));
                relevantRuns = relevantRuns.filter(r => r.overview.id !== focusedRun.overview.id);
                setOtherRelevantRuns(relevantRuns);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleMapFilteringChanged = (filtering) => {
        setShowOnlyInfected(filtering.infectedOnly);
    }

    const loadOtherRun = (run) => {
        let selectedDetailedRun = otherRelevantRuns.find(orr => orr.overview.id === run.id);
        loadSelectedRun(selectedDetailedRun);
    }

    return (
        <div ref={targetRef}>
            <Stack direction="horizontal" style={{ height: '100vh', width: '100vw' }}>
                <Wrapper apiKey="AIzaSyBP89Nlp_P3tZn0UCQaDLaM6P-sO6I8TZA" render={mapRender}>
                    <FAWMap runSites={ currentDetailedRun?.sites } showOnlyInfected={showOnlyInfected} refCount={mapRenderCount} reinitializeZoom={resetZoom} reinitializeCenter={resetCenter} hideZoomControls={hideMapZoomControls}/>
                </Wrapper>
            </Stack>
            <div className="map-usq-logo">
                <Stack direction="horizontal">
                    <img src={QLDGovLogoWhite} height="60px" />
                    <img className="ms-3" src={USQLogoAcronymWhite} height="60px" />
                </Stack>
            </div>
            {
                showPDFCaptureProgress ? (
                    <Spinner id="map-run-pdf-capture-progress" />
                ) : null
            }

            {
                enableMapUI ? (
                <>
                    <CSSTransition
                        nodeRef={runInfoPanelRef}
                        in={showRunInfoPanel}
                        timeout={300}
                        classNames="map-run-info-panel"
                        unmountOnExit
                        onEnter={() => setShowOpenRunInfoButton(false)}
                        onExit={() => setShowOpenRunInfoButton(true)}
                    >
                        <div ref={runInfoPanelRef} id='map-run-info-panel'>
                            <div style={{display: "flex", justifyContent: "end"}}>
                                <FontAwesomeIcon icon={faXmark} onClick={togglePanel} id='map-run-info-panel-close'/>
                            </div>
                            <RunPanelContents
                                name={currentDetailedRun?.overview.name}
                                batch={currentDetailedRun?.overview.batch}
                                dateOfRun={currentDetailedRun?.overview.dateOfRun}
                                location={currentDetailedRun?.overview.town}
                                cropType={currentDetailedRun?.overview.cropType}
                                resolution={currentDetailedRun?.overview.resolution}
                                notes={currentDetailedRun?.overview.notes}
                            />
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        nodeRef={runOverviewPanel}
                        in={insetOverviewPanel}
                        timeout={300}
                        classNames="area-runs-overview-panel-group"
                    >
                        <> 
                            {
                                !hideNonPDFUI ? (
                                    <div ref={runOverviewPanel} id="area-runs-overview-panel-group">
                                        <div id="area-runs-filtering-panel">
                                                <Button 
                                                    id="filtering-panel-home-button"
                                                    onClick={() => {
                                                        setEnableMapUI(false);
                                                        setShowMainPopover(true);
                                                    }}
                                                >
                                                    Return to Main Page
                                                    </Button>
                                                <MapFilteringOptions onFilteringChanged={handleMapFilteringChanged} />
                                        </div>
                                        
                                        <div  id="area-runs-overview-panel"> 
                                            <AreaRunsOverviewPanel currentRun={currentSimpleRun} otherRelevantRuns={[...otherRelevantRuns?.map(orr => orr.getSimpleRunModel())]} onOtherRunClicked={loadOtherRun} />
                                        </div>
                                    </div>        
                                ) : null
                            }
                            
                        </>
                    </CSSTransition>
                </>
                ) : null
            }

            {
                enableMapUI && !hideNonPDFUI && showOpenRunInfoButton && (
                    <div id='map-run-info-panel-toggle-button-container'>
                            <Button
                                onClick={togglePanel}
                            >
                                Open run info
                            </Button>
                    </div>
                )
            }

            {
                enableMapUI && !hideNonPDFUI && (
                    <div id='map-download-pdf-btn' style={ showRunInfoPanel ? {left:"calc(100vw - 470px)"}:{}}>
                        <Button onClick={toggleDownloadPDF} >
                            Download PDF
                        </Button>
                    </div>)
            }

            {
                showMainPopover ? (
                    <Container fluid className="full-page-container background-darken-blur">
                        <LandingPopover 
                            onUploadRunClicked={() => {
                                setShowMainPopover(false);
                                setShowUploadRunPopover(true);
                            }}
                            onFindRunClicked={() => { 
                                setShowMainPopover(false);
                                setShowFindRunPopover(true)
                            }}
                        />
                    </Container>
                    
                ) : null
            }

            {
                showUploadRunPopover ? (
                    <Container fluid className="full-page-container background-darken-blur">
                        <UploadRunPopover 
                            onClose={() => { 
                                setShowUploadRunPopover(false); 
                                setShowMainPopover(true);
                            }} 
                            onLoadRun={(run) => {
                                if (run && run instanceof RunModel) {
                                    setShowUploadRunPopover(false); 
                                    loadSelectedRun(run);
                                } else {
                                    setShowUploadRunPopover(false); 
                                    setShowMainPopover(true);
                                }
                            }}
                            notifyCompleted={(success) => {
                                if (success) {
                                    setShowUploadRunPopover(false);
                                    setShowFindRunPopover(true);
                                }
                            }}
                        />
                    </Container>
                    
                ) : null
            }

            {
                showFindRunPopover ? (
                    <Container fluid className="full-page-container background-darken-blur">
                        <FindRunPopover onClose={(selectedRun) => {
                            setShowFindRunPopover(false);
                            if (selectedRun === undefined) {
                                setShowMainPopover(true);
                            } else {
                                loadSelectedRun(selectedRun);
                            }
                        }} />
                    </Container>
                ) : null
            }
        </div>
        
        
    )
}

export default Map;